import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { combineLatest, of, Observable, Subject } from 'rxjs';
import {
  CoreUiPartialState,
  ApiService,
  getOrderTypes,
  getBranch,
  getDefaultCustomer,
  getActiveOrderVehicle,
  setSelectedVehicle,
  MessageService,
  clearSearchData,
  getUserRole,
  getNonCorpOrderTypes,
  getCashDrawer,
  getBrandDefaults,
} from '@pos-app/core-ui';
import { select, Store } from '@ngrx/store';
import { switchMap, takeUntil, map, take, filter, debounceTime, finalize } from 'rxjs/operators';
import {
  Customer,
  OrderType,
  CustomerVehicle,
  Branch,
  CUSTOMER_TYPE,
  USER_ROLE,
  NonCorpStore,
  ON_BEHALF,
  DefaultCustomer,
  ORDER_TYPE,
} from '@pos-app/data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, FormControl, AbstractControl } from '@angular/forms';
import { OrdersPartialState } from '../../+state/orders.reducer';
import { CreateOrder, LoadParkedOrdersList } from '../../+state/orders.actions';
import { OrderCustomerVehicle, CustomerBillTo, CustomerShipTo } from '../../models/orders-start.model';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { CustomersPartialState } from '../../../customers/+state/customers.reducer';
import { LoadLookupLists, SelectCustomerAction, LoadCustomerVehicles } from '../../../customers/+state/customers.actions';
import { customersQuery } from '../../../customers/+state/customers.selectors';
import { ToastrService } from 'ngx-toastr';
import { CustomersSearchComponent } from 'libs/core-ui/src/lib/components/customer-search/customer-search.component';

@Component({
  selector: 'app-orders-start',
  templateUrl: './orders-start.component.html',
  styleUrls: ['./orders-start.component.scss'],
})
export class OrdersStartComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('customerSearch') customerSearch: CustomersSearchComponent;

  preSelectedBillToCustomer: Customer;
  selectedShipToCustomer: Customer = null;
  selectedBillToCustomer: Customer;
  selectedCustomerVehicle: OrderCustomerVehicle = null;
  selectedVehicle: {
    customerVehicleID: string;
    partsVehicleID: string;
    vehicleDescription: string;
    jdeVehicleID: string;
  } = null;
  orderTypes: OrderType[];
  nonCorpOrderTypes: OrderType[];
  branchList: Branch[];
  startOrderForm: FormGroup;
  isShipTo = false;
  customerShipToList: CustomerShipTo[];
  customerBillToList: CustomerBillTo[];
  activeOrderVehicle$: Observable<any>;
  isAddingVehicle = false;
  loadingCustomerDetails$: Observable<boolean>;
  loadingLookupLists$: Observable<boolean>;
  selectedCustomerVehicleID$: Observable<string>;
  customerVehicleList: CustomerVehicle[];
  isAddingCustomer = false;
  newCustomerNumber: string;
  newCustomer: Customer;
  isLoading = false;
  userRole: string;
  defaultCustomer: DefaultCustomer;
  USER_ROLE = USER_ROLE;
  ORDER_TYPE = ORDER_TYPE;
  nonCorpStores: NonCorpStore[] = [];
  hasCashDrawer: boolean;
  isOrderReferenceRequired: boolean = false;

  get invalid(): boolean {
    return this.startOrderForm?.invalid;
  }

  private unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<OrdersPartialState>,
    private coreUIStore: Store<CoreUiPartialState>,
    private customerStore: Store<CustomersPartialState>,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.newCustomerNumber = this.route.snapshot.queryParams.customerNumber;

    this.startOrderForm = this.formBuilder.group({
      customerNumber: ['', Validators.required],
      shipToNumber: [''],
      orderType: ['', [Validators.required, this.orderTypeValidator()]],
      partsVehicleID: [''],
      customerVehicleID: [''],
      vehicleShortDescription: [''],
      reference: [''],
      branch: ['', [Validators.required]],
      requestDate: formatDate(Date.now(), 'yyyy-MM-ddThh:mm:ss', 'en-au'),
      currencyCode: [''],
      customerTaxRate: [''],
      customerPaymentTerms: [''],
      onBehalf: [ON_BEHALF.branch],
      nonCorpStore: [null],
      shipToName: [''],
      nonCorpStoreSaleYN: ['N'],
    });
    this.dispatchParkedOrdersList();

    this.store
      .select(customersQuery.getSelectedCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((customer) => {
        if (customer) {
          this.startOrderForm.patchValue({
            shipToNumber: customer.BillToNumber,
            shipToName: customer.BillToName,
          });
          if (customer.CustomerType === CUSTOMER_TYPE.retail || customer.referenceRequiredYN === 'N') {
            this.startOrderForm.get('reference').clearValidators();
            this.isOrderReferenceRequired = false;
          } else if (customer.referenceRequiredYN === 'Y') {
            this.startOrderForm
              .get('reference')
              .addValidators([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.minLength(1)]);
            this.isOrderReferenceRequired = true;
            this.createReferenceFieldErrorMessage('reference');
          }
          this.startOrderForm.get('reference').updateValueAndValidity();
        }
      });

    this.selectedCustomerVehicle = null;

    combineLatest([
      this.coreUIStore.pipe(select(getOrderTypes)),
      this.coreUIStore.pipe(select(getNonCorpOrderTypes)),
      this.coreUIStore.pipe(select(getCashDrawer)),
    ])
      .pipe(take(1))
      .subscribe(([orderTypes, nonCorpOrderTypes, cashDrawer]) => {
        if (cashDrawer) {
          const register = JSON.parse(cashDrawer);
          this.hasCashDrawer = register && register.status === 'O';
        }

        this.orderTypes = orderTypes.filter((type) => type.PaymentYN !== 'Y' || this.hasCashDrawer);
        this.nonCorpOrderTypes = nonCorpOrderTypes.filter((type) => type.PaymentYN !== 'Y' || this.hasCashDrawer);
        this.startOrderForm.patchValue({
          orderType: this.orderTypes.length ? this.orderTypes[0].OrderTypeCode : '',
        });
      });

    this.activeOrderVehicle$ = this.coreUIStore.pipe(select(getActiveOrderVehicle));

    this.coreUIStore
      .select(getBrandDefaults)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res) {
          this.startOrderForm.patchValue({
            currencyCode: res.currrencyCode,
          });
        }
      });

    this.loadingCustomerDetails$ = this.customerStore.pipe(select(customersQuery.getLoadingCustomerDetails));

    this.loadingLookupLists$ = this.customerStore.pipe(select(customersQuery.getLoadingLookupLists));

    this.customerStore.dispatch(new LoadLookupLists());
    this.selectedCustomerVehicleID$ = this.customerStore.select(customersQuery.getCustomerVehicleID);
    this.isLoading = true;
    this.startOrderForm.disable();
    this.customerStore
      .select(customersQuery.getCustomerVehicles)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => (this.customerVehicleList = res));

    // External users
    combineLatest([this.coreUIStore.pipe(select(getDefaultCustomer)), this.coreUIStore.pipe(select(getUserRole))])
      .pipe(
        filter(([customer, userRole]) => !!userRole && !this.defaultCustomer),
        debounceTime(500),
        switchMap(([customer, userRole]) => {
          this.userRole = userRole;
          this.defaultCustomer = customer;
          this.getNonCorpStores();

          if (userRole === USER_ROLE.external || this.newCustomerNumber) {
            return this.apiService.customerSearch({
              customerfuzzySearch: '',
              customerNumber: this.newCustomerNumber ? +this.newCustomerNumber : +customer.customerNumber,
              customerType: '',
            });
          }

          return of(null);
        }),
        switchMap((x) => {
          if (x?.SearchResults?.length) {
            this.preSelectedBillToCustomer = x.SearchResults[0];

            this.startOrderForm.patchValue({
              customerNumber: this.preSelectedBillToCustomer.CustomerNumber,
              shipToNumber: this.preSelectedBillToCustomer.CustomerNumber,
              currencyCode: this.preSelectedBillToCustomer.CurrencyCode,
              customerTaxRate: this.preSelectedBillToCustomer.customerTaxRate,
              customerPaymentTerms: this.preSelectedBillToCustomer.customerPaymentTerms,
              branch: this.preSelectedBillToCustomer.BranchCode,
            });

            return this.apiService.fetchShipTos({
              customerNumber: +this.preSelectedBillToCustomer.CustomerNumber,
            });
          }
          return of(null);
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((result) => {
        this.isLoading = false;
        this.startOrderForm.enable();

        if (this.userRole === USER_ROLE.external) {
          this.startOrderForm.controls['branch'].disable({ emitEvent: false });
        }

        if (result) {
          this.customerShipToList = result.SearchResults;
        }
      });

    this.startOrderForm.controls.onBehalf.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value: string) => {
      const orderType = this.startOrderForm.controls.orderType.value;
      // behalf of branch
      if (value === ON_BEHALF.branch) {
        this.startOrderForm.controls.branch.setValidators([Validators.required]);
        this.startOrderForm.controls.nonCorpStore.clearValidators();
        this.startOrderForm.controls.orderType.setErrors(null);
        // orderType = S6, change SO
        if (orderType === ORDER_TYPE.showSaleOrder) {
          this.startOrderForm.patchValue({
            orderType: ORDER_TYPE.saleOrder,
          });
        }
        // behalf of non corp store
      } else {
        this.startOrderForm.controls.nonCorpStore.setValidators([Validators.required]);
        this.startOrderForm.controls.branch.clearValidators();

        // if orderType = SO, change to S6
        if (orderType === ORDER_TYPE.saleOrder) {
          this.startOrderForm.patchValue({
            orderType: ORDER_TYPE.showSaleOrder,
          });
          // check order type is in non corp list
        } else if (this.nonCorpOrderTypes.findIndex((item) => item.OrderTypeCode === orderType) === -1) {
          this.startOrderForm.controls.orderType.setErrors({
            invalidNonCorp: true,
          });
        }
      }
      this.startOrderForm.controls.branch.updateValueAndValidity();
      this.startOrderForm.controls.nonCorpStore.updateValueAndValidity();
    });

    this.getBranchList();
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  getNonCorpStores(): void {
    if (this.userRole === USER_ROLE.showExternal || this.userRole === USER_ROLE.showInternal) {
      this.apiService
        .getNonCorpStoresForShow()
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((storeData) => {
          this.nonCorpStores = storeData.SearchResults || [];
        });
    }
  }

  getBranchList(): void {
    this.apiService
      .getBranchList()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((branchData) => {
        this.branchList = branchData.SearchResults;
      });
  }

  selectShipToCustomer(e) {
    this.coreUIStore.dispatch(clearSearchData());
    this.isShipTo = false;
    this.selectedCustomerVehicle = null;
    this.selectedVehicle = null;

    this.selectedShipToCustomer = e;

    this.startOrderForm.patchValue({
      customerNumber: '',
    });
    if (this.selectedShipToCustomer) {
      this.newCustomerNumber = null;
      this.startOrderForm.patchValue({
        // To take shipto name and number from customer summary
        shipToNumber: '',
        shipToName: '',
        branch: this.selectedShipToCustomer.BranchCode,
        currencyCode: this.selectedShipToCustomer.CurrencyCode,
        customerPaymentTerms: this.selectedShipToCustomer.customerPaymentTerms,
        customerTaxRate: this.selectedShipToCustomer.customerTaxRate,
      });
      if (this.selectedShipToCustomer.CustomerType === CUSTOMER_TYPE.shipTo) {
        this.isShipTo = true;
        this.selectedBillToCustomer = null;
        this.apiService
          .fetchCustomerBillTo({
            customerShipTo: this.selectedShipToCustomer.CustomerNumber,
          })
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((result) => {
            if (result) {
              this.customerBillToList = result.SearchResults;
              this.startOrderForm.patchValue({
                customerNumber: this.customerBillToList[0].billToNumber,
              });
              this.startOrderForm.controls.reference.setValidators([Validators.required]);
              this.startOrderForm.controls.reference.updateValueAndValidity();
            }
          });
      } else {
        if (this.selectedShipToCustomer.CustomerType === CUSTOMER_TYPE.retail) {
          this.coreUIStore
            .pipe(select(getBranch))
            .pipe(take(1), takeUntil(this.unSubscribe$))
            .subscribe((x) => {
              if (x) {
                this.startOrderForm.patchValue({ branch: x.BranchCode });
              }
            });
        }
        this.startOrderForm.patchValue({
          customerNumber: this.selectedShipToCustomer.CustomerNumber,
        });
      }
    }

    if (this.startOrderForm.controls.customerNumber.value) {
      const customerNumber = this.startOrderForm.controls.customerNumber.value;
      this.customerStore.dispatch(new SelectCustomerAction(customerNumber, true));
      this.store.dispatch(
        new LoadCustomerVehicles({
          customerNumber,
          retiredVehicleYN: 'Y',
        })
      );
      this.apiService
        .fetchDefaultVehicle({
          customerNumber,
        })
        .pipe(
          map((res) => {
            if (res.ErrorFlag === '1') {
              return null;
            }
            return res;
          }),
          takeUntil(this.unSubscribe$)
        )
        .subscribe((x) => {
          if (x && x.partsVehicleID !== 0) {
            this.selectedCustomerVehicle = {
              partsVehicleId: x.partsVehicleID,
              customerVehicleId: x.customerVehicleID,
              vehicleShortDescription: x.vehicleDescription,
            };
          }
        });
    }
  }
  selectBillToCustomer(e) {
    this.selectedBillToCustomer = e;
  }

  selectCustomerVehicle(v: CustomerVehicle) {
    this.selectedVehicle = null;
    this.selectedCustomerVehicle = {
      partsVehicleId: v.partsVehicleID,
      customerVehicleId: v.customerVehicleID,
      vehicleShortDescription: v.ShortDescription,
    };
    this.modalService.dismissAll();
  }

  getSelectedVehicle(v) {
    this.selectedVehicle = v;
    if (!this.selectedVehicle.customerVehicleID) {
      this.isAddingVehicle = true;
      return true;
    }
    this.selectedCustomerVehicle = null;
    return false;
  }

  closeAddingVehicle(customerVehicleID = null) {
    this.modalService.dismissAll();
    this.isAddingVehicle = false;
    if (customerVehicleID) {
      this.selectedCustomerVehicle = null;
      const newCusomterVehicle = this.customerVehicleList.find((x) => x.customerVehicleID === customerVehicleID);
      this.selectedVehicle = Object.assign([], this.selectedVehicle);
      this.selectedVehicle['customerVehicleID'] = customerVehicleID;
      this.selectedVehicle['vehicleDescription'] = newCusomterVehicle.ShortDescription;
      const newSelectedVehicle = {
        customerVehicleID: this.selectedVehicle.customerVehicleID,
        partsVehicleID: this.selectedVehicle.partsVehicleID,
        vehicleDescription: this.selectedVehicle.vehicleDescription,
        jdeVehicleID: this.selectedVehicle.jdeVehicleID,
      };
      this.apiService
        .updateSelectedVehicle(newSelectedVehicle)
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          if (res) {
            this.coreUIStore.dispatch(setSelectedVehicle(newSelectedVehicle));
            this.messageService.dispatchAction(setSelectedVehicle(newSelectedVehicle));
          }
        });
    } else {
      this.selectedVehicle = null;
    }
  }

  open(content) {
    this.modalService.open(content, {
      windowClass: 'garage-modal',
      size: 'xl',
    });
  }

  vehicleDescription() {
    return this.selectedCustomerVehicle
      ? this.selectedCustomerVehicle.vehicleShortDescription
      : this.selectedVehicle
      ? this.selectedVehicle.vehicleDescription
      : '';
  }

  createOrder(toOrder = false) {
    let customerName = this.selectedShipToCustomer?.CustomerName;

    if (this.selectedVehicle) {
      this.startOrderForm.patchValue({
        partsVehicleID: this.selectedVehicle.partsVehicleID,
        customerVehicleID: this.selectedVehicle.customerVehicleID,
        vehicleShortDescription: this.selectedVehicle.vehicleDescription,
      });
    } else if (this.selectedCustomerVehicle && this.selectedCustomerVehicle.customerVehicleId) {
      this.startOrderForm.patchValue({
        partsVehicleID: this.selectedCustomerVehicle.partsVehicleId,
        customerVehicleID: this.selectedCustomerVehicle.customerVehicleId,
        vehicleShortDescription: this.selectedCustomerVehicle.vehicleShortDescription,
      });
    }
    if (this.startOrderForm.controls.onBehalf.value === ON_BEHALF.nonCorp) {
      this.startOrderForm.patchValue({
        shipToNumber: this.startOrderForm.controls.nonCorpStore.value.customerNumber,
        shipToName: this.startOrderForm.controls.nonCorpStore.value.customerName,
        branch: this.defaultCustomer.defaultBranch,
        nonCorpStoreSaleYN: 'Y',
      });
    } else if (this.selectedShipToCustomer?.CustomerType === CUSTOMER_TYPE.shipTo) {
      this.startOrderForm.patchValue({
        shipToNumber: this.selectedShipToCustomer?.CustomerNumber,
        shipToName: this.selectedShipToCustomer?.CustomerName,
      });
    }

    const data = {
      ...this.startOrderForm.getRawValue(),
      customerName,
    };

    this.store.dispatch(new CreateOrder({ data, toOrder }));
  }

  clearVehicle() {
    this.selectedCustomerVehicle = null;
    this.selectedVehicle = null;
  }

  cancel() {
    window.history.back();
  }

  addNewCustomer() {
    this.isAddingCustomer = true;
    return true;
  }

  handleNewCustomer(customerNumber: string) {
    this.modalService.dismissAll();
    this.isAddingCustomer = false;

    if (customerNumber) {
      this.newCustomerNumber = customerNumber;
      this.isLoading = true;
      this.apiService
        .customerSearch({
          customerfuzzySearch: '',
          customerNumber: +this.newCustomerNumber,
          customerType: '',
        })
        .pipe(
          takeUntil(this.unSubscribe$),
          finalize(() => (this.isLoading = false))
        )
        .subscribe((res) => {
          this.newCustomer = res.SearchResults.find((customer) => +customer.CustomerNumber === +this.newCustomerNumber) || res.SearchResults[0];
          this.customerSearch.selectCustomer(this.newCustomer);
        });
    }
  }

  getCustomerName(): string {
    if (this.selectedShipToCustomer) {
      return this.selectedShipToCustomer.CustomerName + ' (' + this.selectedShipToCustomer.CustomerNumber + ')';
    }

    if (this.newCustomer) {
      return this.newCustomer.CustomerName + ' (' + this.newCustomer.CustomerNumber + ')';
    }

    return '';
  }

  orderTypeValidator(): ValidatorFn {
    return (orderType: FormControl): ValidationErrors => {
      let errors = null;
      if ((orderType.value === ORDER_TYPE.creditPrice || orderType.value === ORDER_TYPE.creditReturn) && !this.hasCashDrawer) {
        errors = { invalidOrderType: true };
      }
      return errors;
    };
  }

  //Generate Reference field error message toastr
  createReferenceFieldErrorMessage(key: string) {
    if (key === 'reference') {
      if (this.isValidatorExist(key)) {
        this.startOrderForm
          .get(key)
          .valueChanges.pipe(takeUntil(this.unSubscribe$))
          .subscribe((val) => {
            if (val.trim() === '') {
              this.startOrderForm.controls.reference.setErrors({
                invalid: true,
              });
              this.toastr.error('Reference field cannot be empty, please provide value.');
            } else {
              this.startOrderForm.controls.reference.setErrors(null);
            }
          });
      }
    }
  }

  //Check if validation exist or not
  isValidatorExist(field: string) {
    const form_field = this.startOrderForm.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  dispatchParkedOrdersList() {
    this.store.dispatch(new LoadParkedOrdersList());
  }
}
