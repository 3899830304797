<pos-app-spinner [show]="isBusy"></pos-app-spinner>
<div *ngIf="role$ | async as role">
  <nav class="navbar navbar-expand-lg" *ngIf="defaultBranch$ | async as branch" [ngStyle]="checkNavDisable() ? { 'pointer-events': 'none' } : ''">
    <div class="row w-100">
      <div class="col-3 nav-link arbonline-logo d-flex align-items-center">
        <span (click)="navigateToHome()" class="pr-2 d-flex">
          <div class="logo-image"></div>
          <span *ngIf="!configService.AppConfig.enableTheme" class="px-2 my-auto text-white logo-text d-none d-lg-block"> Online </span>
        </span>
        <mat-icon
          *ngIf="!(isExternalUser$ | async) && (loggedinUserId$ | async) && (defaultCustomer$ | async) as customer"
          matBadge="{{ alertCount }}"
          matBadgeColor="warn"
          matBadgeOverlap="false"
          class="calendar-icon mt-neg-1rem"
          (click)="navigateToTaskCalendar(customer.customerNumber)"
        >
          event
        </mat-icon>
      </div>
      <div class="col-9 text-white my-auto d-flex justify-content-end">
        <div class="my-auto" *ngIf="{ value: defaultCustomer$ | async } as customer">
          <ul class="navbar-nav align-items-center">
            <li
              class="nav-item px-2 d-none d-lg-block"
              *ngIf="(loggedinUserId$ | async) && (forInternalUser(role) || role === USER_ROLE.showExternal) && isCashDrawer"
            >
              <a class="nav-link" (click)="quickSale(branch)" routerLinkActive="active"> QUICK SALE </a>
            </li>
            <li
              class="nav-item px-2 d-none d-lg-block"
              *ngIf="
                (loggedinUserId$ | async) &&
                ((forExternalUser(role) && (orderTypes$ | async)?.length > 0) ||
                  forInternalUser(role) ||
                  role === USER_ROLE.showExternal ||
                  canMaintainOrders(securityFunctions$ | async))
              "
            >
              <a class="nav-link" (click)="newOrder()" routerLinkActive="active"> NEW ORDER {{ customer.ValidOrderTypes | json }}</a>
            </li>
            <li class="nav-item px-2 d-none d-lg-block" *ngIf="activeOrder?.orderNumber">
              <div class="d-xl-flex d-none" *ngIf="(loggedinUserName$ | async) || (defaultCustomer$ | async)">
                <div class="my-auto px-2 text-uppercase">
                  <a class="nav-link mb-0 pb-0" (click)="viewOrder()"> VIEW ORDER </a>
                  <div *ngIf="openOrder">
                    <a class="nav-link my-0 py-0" (click)="viewOrder()">
                      <span class="text-truncate" style="max-width: 180px">
                        {{ openOrder.customerName }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </li>

            <span class="px-2">
              <li
                class="nav-item"
                *ngIf="
                  (loggedinUserId$ | async) &&
                  (forInternalUser(role) || role === USER_ROLE.showExternal || (securityFunctions$ | async).indexOf('MaintainOrders') !== -1)
                "
              >
                <a class="nav-link" (click)="navigateTo('/pos/my-orders', false, true)" routerLinkActive="active">
                  <span [matBadge]="parkedOrderListLength">MY ORDERS</span>
                </a>
              </li>
            </span>

            <ng-template [ngIf]="(loggedinUserId$ | async) && forExternalUser(role) && (securityFunctions$ | async).indexOf('AccountScreen') !== -1">
              <li class="nav-item px-2">
                <a class="nav-link" (click)="navigateToAccountUser(customer.value.customerNumber)" routerLinkActive="active"> ACCOUNT </a>
              </li>
            </ng-template>
            <li class="nav-item px-2" *ngIf="isShowPopDrawer$ | async">
              <a class="nav-link" (click)="openCashDrawer()" routerLinkActive="active"> POP DRAWER </a>
            </li>
            <li class="nav-item px-2" *ngIf="(loggedinUserId$ | async) && (role$ | async) !== USER_ROLE.guest">
              <a class="nav-link" (click)="navigateTo('/pos/customers/search', true)" routerLinkActive="active"> SEARCH </a>
            </li>
            <div *ngIf="!(isExternalUser$ | async) && (loggedinUserId$ | async)" ngbDropdown class="d-flex align-items-center">
              <button class="text-white" id="dropdown-nav" ngbDropdownToggle>ADMIN</button>
              <div ngbDropdownMenu aria-labelledby="dropdown-nav" class="dropdown-menu-right">
                <button
                  ngbDropdownItem
                  (click)="navigateTo('/pos/register')"
                  *ngIf="(loggedinUserId$ | async) && forInternalUser(role) && canManageRegisterAdmin(securityFunctions$ | async)"
                  routerLinkActive="active"
                >
                  REGISTER ADMIN
                </button>
                <button ngbDropdownItem (click)="navigateTo('/pos/allocate-fitter')" routerLinkActive="active">ALLOCATE FITTER</button>
                <button *ngIf="brandDefaults?.enableWorkshopBookingTF" ngbDropdownItem (click)="goToScheduler()" routerLinkActive="active">WORKSHOP</button>
              </div>
            </div>

            <li class="nav-item px-2" *ngIf="loggedinUserId$ | async">
              <a class="nav-link" (click)="navigateTo('/pos/resources')" routerLinkActive="active"> RESOURCES </a>
            </li>
            <li class="nav-item px-2" *ngIf="loggedinUserId$ | async">
              <a class="nav-link" (click)="isFeedback = true" routerLinkActive="active"> FEEDBACK </a>
            </li>
          </ul>
        </div>
        <div class="my-auto d-flex align-items-center" *ngIf="!(loggedinUserId$ | async)">
          <div class="d-none d-xl-flex">
            <div class="vl"></div>
            <span class="my-auto px-3"> PLEASE LOG IN FOR MORE FEATURES </span>
          </div>
          <button class="btn text-white" (click)="navigateTo('/pos/login')">LOGIN</button>
        </div>
        <div class="my-auto d-flex align-items-center" *ngIf="loggedinUserId$ | async">
          <div class="d-xl-flex d-none" *ngIf="(loggedinUserName$ | async) || (defaultCustomer$ | async)">
            <div class="vl"></div>
            <div class="my-auto pl-2">
              <mat-icon (click)="switchUser()" title="Switch User"> supervisor_account </mat-icon>
            </div>
            <div class="my-auto px-2 text-uppercase">
              <div>
                {{ loggedinUserName$ | async }}
              </div>
              <div *ngIf="forInternalUser(role) || role === USER_ROLE.showExternal">
                {{ (defaultBranch$ | async).BranchDescription }}
              </div>
              <div *ngIf="role === USER_ROLE.external">
                {{ jdeSession?.CustomerName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div ngbDropdown class="d-inline-block">
                <button class="btn text-white" id="dropdownBasic1" ngbDropdownToggle>ACCOUNT</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right" style="left: -8.5rem">
                  <div class="showroom-toggle" *ngIf="role !== USER_ROLE.guest">
                    SHOWROOM VIEW <span class="pr-2"></span>
                    <label class="switch">
                      <input type="checkbox" (change)="toggleShowroom($event.target.checked)" [checked]="isShowroomView" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <button ngbDropdownItem (click)="navigateTo('/pos/manageusers/internal')" *ngIf="canManageInternalUser(securityFunctions$ | async)">
                    MANAGE INTERNAL USERS
                  </button>
                  <button
                    ngbDropdownItem
                    (click)="navigateTo('/pos/manageusers/external')"
                    *ngIf="canManageUsers(securityFunctions$ | async) && role !== USER_ROLE.showExternal && role !== USER_ROLE.showInternal"
                  >
                    MANAGE REGISTERED USERS
                  </button>
                  <button ngbDropdownItem (click)="navigateTo('/pos/manageusers/show')" *ngIf="canManageShowUsers(securityFunctions$ | async)">
                    MANAGE SHOW USERS
                  </button>
                  <button
                    ngbDropdownItem
                    (click)="navigateTo('/pos/manageusers/profile')"
                    *ngIf="forInternalUser(role) || canManageMyProfile(securityFunctions$ | async)"
                  >
                    MANAGE MY PROFILE
                  </button>
                  <button
                    ngbDropdownItem
                    (click)="resetPassword(loggedInUserId)"
                    *ngIf="!forInternalUser(role) && (loggedinUserId$ | async) as loggedInUserId"
                  >
                    RESET PASSWORD
                  </button>
                  <button ngbDropdownItem (click)="switchUser()" class="d-xl-none">SWITCH USER</button>
                  <button class="ml-0" ngbDropdownItem (click)="logout()">LOGOUT</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div *ngIf="showSpotlightNav && loggedinUserId$ | async as loggedInUserId" class="product-spotlight">
    <pos-app-product-spotlight #spotlightElement (navigateToHomeEvent)="navigateToHome()"></pos-app-product-spotlight>
  </div>

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h3 class="arb-large-heading" id="modal-basic-title">SELECT BRANCH</h3>
    </div>
    <div class="modal-body">
      <ng-select (change)="selectBranch($event)">
        <ng-option *ngFor="let branch of branchList" [value]="branch.BranchCode">
          <span class="pr-1" *ngIf="branch.BranchCode"> {{ branch.BranchCode }} - </span>
          {{ branch.BranchDescription }}
        </ng-option>
      </ng-select>
    </div>
  </ng-template>

  <pos-app-feedback
    *ngIf="isFeedback"
    (closeFeedback)="isFeedback = false"
    [isExternalUser]="!forInternalUser(role)"
    [customerDetails]="defaultCustomer$ | async"
  >
  </pos-app-feedback>
</div>
